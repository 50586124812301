import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import Links from '../Links'
import './style.scss'

import { ReactComponent as Logo } from '../../assets/logo/logo.svg'

class Sidebar extends React.Component {
  render() {
    const { location } = this.props
    const {
      author,
      subtitle,
    } = this.props.data.site.siteMetadata
    const isHomePage = get(location, 'pathname', '/') === '/'

    /* eslint-disable jsx-a11y/img-redundant-alt */
    const authorBlock = (
      <div>
        <Link style={{ width: '100%', display: 'block', textAlign: 'center' }} to="/">
          {Logo()}
        </Link>
        {isHomePage ? (
          <h1 className="sidebar__author-title">
            <Link className="sidebar__author-title-link" to="/about">
              {author.name}
            </Link>
          </h1>
        ) : (
          <h2 className="sidebar__author-title">
            <Link className="sidebar__author-title-link" to="/about">
              {author.name}
            </Link>
          </h2>
        )}
        <p className="sidebar__author-subtitle">{subtitle}</p>
      </div>
    )
    /* eslint-enable jsx-a11y/img-redundant-alt */

    return (
      <div className="sidebar">
        <div className="sidebar__inner">
          <div className="sidebar__author">{authorBlock}</div>
          <div className="sidebar__links">
            <Links data={author} />
          </div>
        </div>
      </div>
    )
  }
}

export default Sidebar
